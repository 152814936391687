<template>
  <div class="container">
    <div class="large-12 medium-12 small-12 cell">
      <label>File
        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
      </label>
      <v-btn color="primary" dark slot="activator" class="mb-2" v-on:click="submitFile()">Upload</v-btn>
      <v-btn color="primary" dark slot="activator" class="mb-2" v-on:click="exportExcel()">Export Excel</v-btn>
      <v-btn color="primary" dark slot="activator" class="mb-2" v-on:click="customsExcel()">Customs Excel</v-btn>
      <v-btn color="primary" dark slot="activator" class="mb-2" v-on:click="PowerPointPeview()">Power Point Peview</v-btn>
      <v-btn color="primary" dark slot="activator" class="mb-2" v-on:click="retrieveGross()">retrieveGross</v-btn>
      <v-btn color="primary" dark slot="activator" class="mb-2" v-on:click="updateGross()">updateGross</v-btn>
    </div>
    <vue-editor v-model="content" id="editor1"/>
    <v-tabs v-model="active" color="cyan" dark slider-color="yellow">
      <v-tab v-for="sheet in sheets" :key="sheet.sheetName" ripple>
        {{ sheet.sheetName }}
      </v-tab>
      <v-tab-item v-for="sheet in sheets" :key="sheet.sheetName">
        <v-data-table :headers="sheet.headers"
                      :items="sheet.data"
                      select-all
                      :pagination.sync="pagination"
                      item-key="0"
                      class="elevation-1">
          <template slot="headers" slot-scope="props">
            <tr>
                <th v-for="header in props.headers"
                    :key="header.text"
                    :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                    @click="changeSort(header.value)">
                  <v-icon small>arrow_upward</v-icon>
                    {{ header.text }}
                </th>
            </tr>
          </template>
          <template slot="items" slot-scope="props">
            <tr :active="props.selected" @click="props.selected = !props.selected">
              <td v-for="col in filterColumnsRowNull(Object.keys(sheet.data[0]))" class="text-xs-left">{{ props.item[col] }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
  </div>
</template>
<style>
  #editor1, #editor2 {
      height: 350px;
    }
  .echarts {
      width: 400px;
      height: 400px;
  }
  .echartsMap {
      width: 800px;
      height: 800px;
  }
</style>
<script>
import axios from 'axios'
import Base64Binary from 'base64-arraybuffer'
import { VueEditor, Quill } from 'vue2-editor'
var Keyboard = Quill.import('modules/keyboard')
class CustomKeyboard extends Keyboard {
  static DEFAULTS = {
    ...Keyboard.DEFAULTS,
    bindings: {
      ...Keyboard.DEFAULTS.bindings,
      ['list autofill']: undefined,
    }
  }
}
Quill.register('modules/keyboard', CustomKeyboard)
export default {
  components: { VueEditor
  },
  data: () => ({
    file: '',
    active: null,
    sheets: [],
    pagination: {
      sortBy: '0',
      rowsPerPage: 100
    },
    content: '<p>Some i<span style="color: rgb(230, 0, 0);">niti</span>a<strong>l con</strong><span style="color: rgb(0, 102, 204);">te</span>nt</p>'
  }),
  computed: {
  },
  created: function () {
  },
  methods: {
    toColumnName (num) {
      for (var ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
        ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret
      }
      return ret
    },
    filterColumnsRowNull (cols) {
      return cols.filter(col => {
        return col !== 'Row_Null'
      })
    },
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    retrieveCustoms (provinceCode, proviceName) {
      axios.post(process.env.VUE_APP_API + '/excel/retrieveCustoms', {
        province: provinceCode
      })
        .then(response => {
          if (response.data.isSuccess) {
            var attributeName = response.data.data[0].province
            var amount = response.data.data[0].sum_income

            this.$notify({
              group: 'map',
              title: 'Information',
              text: `จังหวัด ${proviceName} ${attributeName} ${amount}`
            })
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
    },
    submitFile () {
      let formData = new FormData()
      formData.append('file', this.file)
      axios.post(process.env.VUE_APP_API + '/readExcel', formData,
        {
          headers: {'Content-Type': 'multipart/form-data'}
        }
      ).then(response => {
        if (response.data.isSuccess) {
          this.sheets = response.data.sheet
          for (var i = 0; i < this.sheets.length; i++) {
            this.sheets[i].headers = []
            for (var val in this.sheets[i].data[0]) {
              var colName = (val === '0' ? '#' : this.toColumnName(parseInt(val)))
              if (val !== 'Row_Null') {
                this.sheets[i].headers.push({text: colName, value: val, align: 'center'})
              }
            }
          }
        } else {
          this.$swal({type: 'error', title: response.data.reasonText})
        }
      })
      .catch(e => {
        this.$swal({type: 'error', title: e.message})
      })
    },
    exportExcel () {
      axios.post(process.env.VUE_APP_API + '/excel/testExcel', {}, {}
      ).then(response => {
        if (response.data.isSuccess) {
          const url = window.URL.createObjectURL(new Blob([Base64Binary.decode(response.data.pdf_base64)], {type: 'application/pdf; charset=utf-8'}))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'file.pdf')
          document.body.appendChild(link)
          link.click()
        } else {
          this.$swal({type: 'error', title: response.data.reasonText})
        }
      })
      .catch(e => {
        this.$swal({type: 'error', title: e.message})
      })
    },
    customsExcel () {
      let formData = new FormData()
      formData.append('file', this.file)
      axios.post(process.env.VUE_APP_API + '/excel/testCustoms', formData,
        {
          headers: {'Content-Type': 'multipart/form-data'}
        }
      ).then(response => {
        if (response.data.isSuccess) {
        } else {
          if (response.data.errExcel != null) {
            this.$swal({type: 'error', title: 'errExcel ' + response.data.errExcel.length})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        }
      })
      .catch(e => {
        this.$swal({type: 'error', title: e.message})
      })
    },
    PowerPointPeview () {
      let formData = new FormData()
      formData.append('file', this.file)
      axios.post(process.env.VUE_APP_API + '/test/ppttoPDF', formData,
        {
          headers: {'Content-Type': 'multipart/form-data'}
        }
      ).then(response => {
        if (response.data.isSuccess) {
          const blob = new Blob([Base64Binary.decode(response.data.pdf_base64)], {type: 'application/pdf; charset=utf-8'})
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        } else {
          this.$swal({type: 'error', title: response.data.reasonText})
        }
      })
      .catch(e => {
        this.$swal({type: 'error', title: e.message})
      })
    },
    pathoPdf () {
      axios.post('http://localhost/PathoAndroid/PathoService.svc/GetPathoReportPdf', {
        val: {
          RegisterNo: 'R180323-0120',
          RevNo: 1,
          DocNo: 9,
          ReportNo: 1,
          PathoType: 'O',
          PrePrint: 'Y'
        }
      })
      .then(response => {
        if (response.data.success) {
          const url = window.URL.createObjectURL(new Blob([Base64Binary.decode(response.data.table[0].pdf)], {type: 'application/pdf; charset=utf-8'}))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'file.pdf')
          document.body.appendChild(link)
          link.click()
        } else {
          this.$swal({type: 'error', title: response.data.reasonText})
        }
      })
      .catch(e => {
        this.$swal({type: 'error', title: e.message})
      })
    },
    retrieveGross () {
      axios.post(process.env.VUE_APP_API + '/pathoRegisterReport/getGross' + process.env.VUE_APP_DATABASE_TYPE, {
        data: {
          register_no: 'R190430-0049',
          rev_no: 1
        }
      })
        .then(async response => {
          if (response.data.isSuccess) {
            this.content = response.data.data[0]['gross_new']
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    updateGross () {
      // var blob = new Blob(
      //   [ this.content ],
      //   {
      //     type: 'text/plain;charset=utf-8'
      //   }
      // )
      // var bytes = this.content.split('').map(function (c) { return c.charCodeAt() })
      axios.post(process.env.VUE_APP_API + '/pathoRegisterReport/updateGross' + process.env.VUE_APP_DATABASE_TYPE, {
        data: {
          register_no: 'R190430-0049',
          rev_no: 1,
          doc_no: 5,
          gross_base64: this.content
        }
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.$swal({type: 'success', title: response.data.reasonText})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
  }
}
</script>
